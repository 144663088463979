body {
  background-color: #fcfcfc;
}
@media (max-width: 1040px) {

  .HomeHeader {
    color: #d30000;
  }

  .CompetitionHeader {
    color: #d30000;
    text-decoration: none;
  }

}
@media (min-width: 1041px) {

  .HomeHeader {
    color: #d30000;
    justify-content: left;
    text-align: justify;
  }

  .CompetitionHeader {
    color: #d30000;
    text-decoration: none;
  }

  .content {
    justify-content: left;
    text-align: left;
  }

  .column {
    float: left;
    width: 50%;
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

}

.competitions {
  padding-bottom: 50px;
}
