body {
  background-color: #fcfcfc;
}
.MainHeader {
  color: #d30000;
}
.HeaderLinks {
  color: black;
  background-color: transparent;
  text-decoration: none;
}