.NavBar{
  z-index: 0;
  position: relative;
  margin-left: auto;
  margin-right: 3px;
  align-items: center;
  float: right;
  display: flex;
  width: 690px;
}
.NavBarResponsive{
  z-index: 1;
  position: relative;
  margin-left: auto;
  margin-right: 3px;
  align-items: center;
  float: right;
  display: flex;
  width: 690px;
}
.DropDown {
  display: none;
}
.IconBar {
  width: 35px;
  height: 3px;
  background-color: #868686;
  margin: 6px 0;
  margin-right: 3px;
  margin-left: 3px;
  border-radius: 10px;
}
.Logo, .Spin {
  flex: 1;
  height: 10rem;
  width: 10rem;
  pointer-events: none;
}
.LogoName {
  width: 235px;
}
@media (prefers-reduced-motion: no-preference) {
  .Spin {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media (min-width: 1041px) {
  .MenuLinks {
    width: 120px;
    background-color: #282c34;
    color: white;
    border-style: hidden;
    padding: 3px;
    font-family: 'Trebuchet MS';
    font-size: 1rem;
    margin: auto;
    text-align: center;
    text-decoration: none;
  }
  .NavBarResponsive {
    margin-left: auto;
    margin-right: 3px;
    align-items: center;
    float: right;
    display: flex;
    width: 690px;
  }
}
@media (max-width: 1040px) {
  .NavBar {
    justify-content: right;
    display: none;
  }
  .MenuLinks {
    width: 100%;
    font-size: 30px;
    font-family: 'Trebuchet MS';
    padding-bottom: 20px;
    margin-top: -1px;
    background-color: #282c34;
    color: white;
    border-style: hidden;
    text-align: center;
    text-decoration: none;
  }
  .NavBarResponsive {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 165px;
    width: 100%;
    align-items: center;
  }
  .DropDown {
    display: block;
    border-style: solid;
    border-width: 2px;
    border-color: #c5c5c5;
    border-radius: 5px;
    height: 35px;
    width: 41px;
    position: absolute;
    right: 40px;
    top: 40px;
  }
  .NavBarResponsive {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 165px;
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 350px) {
  .Logo {
    display: none;
  }
  .NavBarResponsive {
    top: 135px;
  }
}
.active, .MenuLinks:hover {
  color: rgb(255, 0, 0);
  cursor: pointer;
  transition: color 0.4s;
}
.DropDown:hover {
  cursor: pointer;
}
.App-header {
  z-index: 5;
  background-color: #282c34;
  min-height: 150px;
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: white;
}

.clickableLogo{
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}