@media (max-width: 1040px) {
  .Element {
    margin: auto;
    align-items: center;
    text-align: center;
    width: 93%;
    font-size: 20px;
  }
}

@media (min-width: 1041px) {
  .Element {
    margin: auto;
    align-items: center;
    text-align: center;
    width: 70%;
    font-size: 20px;
  }
}

.boldText{
  font-weight: bold;
}
.styreTable{
  margin: auto;
  border-spacing: 20px 2px;
}
