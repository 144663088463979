.App-footer {
    z-index: 5;
    background-color: #282c34;
    min-height: 50px;
    font-size: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    /* remove commment to make footer sticky
    position: sticky;
    bottom: 0; */
}

.footer-links {
    display: flex;
    align-items: center;
}

.link {
    color: white;
    text-decoration: none;
}

.link:hover {
    color: rgb(255, 0, 0);
    cursor: pointer;
    transition: color 0.4s;
}

@media (max-width: 1040px) {
    .footer-links {
        flex-direction: column;
    }

    .link {
        margin: 5px 0;
        display: flex;
    }
}

@media (min-width: 1041px) {
    .footer-links {
        flex-direction: row;
    }

    .link {
        margin: 0 15px;
    }
}