.BliMedlem {
    text-align: center;
    font-size: 20px;
    padding-bottom: 80px;
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.steg {
  background: lightGrey;
  border-radius: 15px 15px 15px 15px;
  padding: 10px
}
