@media (max-width: 1040px) {
  .Butikker {
    margin: auto;
    align-items: center;
    text-align: center;
    width: 93%;
    font-size: 20px;
  }
}

@media (min-width: 1041px) {
  .Butikker {
    margin: auto;
    align-items: center;
    text-align: center;
    width: 93%;
    font-size: 20px;
  }
}

body {
  background-color: #fcfcfc;
}
.MainHeader {
  color: #d30000;
}
.HeaderLinks {
  color: black;
  background-color: transparent;
  text-decoration: none;
}
