.Ressurser {
    text-align: center;
    font-size: 20px;
    margin-bottom: 40px;
}

.resource-links {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.resource-link {
    color: black;
    text-decoration: none;
    display: flex;
}
.resource-link:hover {
    color: rgb(255, 0, 0);
    cursor: pointer;
    transition: color 0.4s;
}
