@media (max-width: 1040px) {
  .compTable{
    width: 100%;
    margin: auto;
    border-collapse: collapse;
  }
}
@media (min-width: 1041px) {
  .compTable{
    width: 70%;
    margin: auto;
    /* margin: -40px; */
    border-collapse: collapse;
  }
}
.comp {
  justify-content: center;
  align-items: center;
}
.compRow {
  transition: background-color 0.2s;
}
.compRow:nth-child(odd) {
  background-color: #e2e2e2;
}
.compName {
  width: 30%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.compCity {
  width: 30%;
}
.venue {
  font-style: italic;
}
.compDate {
  width: 30%;
}
.compLinks:link, .compLinks:visited {
  text-decoration: none;
  color: #d30000;
}
.compRow:hover {
  background-color: #c2c2c2;
}
