body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Main {
  align-items: center;
  text-align: center;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 93%;

}

/* @media (min-width: 1041px) {
  .Main {
  }
}

@media (max-width: 1040px) {
  .Main {
  }
} */